import { createI18n } from 'vue-i18n'
const i18n = createI18n({
  locale: 'en',
  messages: {
    'en': {
      services:'services',
      work:'work',
      about:'about',
      contact:'contact',
      report:'report',
      start_project:'Start a project',
      who_are_we:"who are we?",
      hi_we_are_puremind:"hi we are puremind",
      welcome_to:"Welcome to",
      pureminde:"PureMinds",
      header_text_2:", a leading media production company dedicated to creating captivating visual experiences.",
      about_puremind:"About Puremind",
      meet_the_team:"Meet the Team",
      our_experties:"Our Experties",
      expertiesText:"Strategies for advancing your business to new heights.",
      expertiesText_2:"Welcome to PureMinds, a leading media production company dedicated to creating captivating visual experiences.",
      view_all_services:"View all services",
      advertising_campaigns:'Advertising \n Campaigns',
      exhibition_event:'Exhibition \n & Event',
      film_production:'Film \n Production',
      event_entertainment:'Event \n & Entertainment',
      brand_identity:'Brand \n Identity',
      creative_solutions:'Creative \n Solutions',
      strategies_consulting:'Strategies &\n Consulting ',
      digital_marketing:'Digital \n marketing',
      view_more:"View More",
      our_work:"Our Work",
      seeWork:"See what we've been working on",
      view_details:"View \n details",
      our_work_text_2:"Do you feel ready? \n let's work together",
      contact_us:"Contact us",
      our_stats:"our stats",
      clients:"Clients",
      project:"Project",
      male_female:"Male:Female",
      project:"Project",
      shameful_plug_text_1:"our company collaboration with a diverse range of clients, including startups, global corporations, and government insitutions, porviding tailored solution that drive success and faster growth acreoss the region",
      shameful_plug_text_2:"we work with start-up businesses through to global organisation.",
      shameful_plug:"shameful plug",
      get_in_touch:"get in touch today",
      testimonials:"testimonials",
      testimonialsText:"client testimonials Real result, Real Feedback",
      read_more_reviews:"read more reviews",
      learn: "learn",
      about: "about",
      culture: "culture",
      testimonials: "testimonials",
      processes: "processes",
      faqs: "FAQs",
      branding_faqs: "branding FAQs",
      new: "new",
      blog:"blog",
      explore:"explore",
      home:"home ",
      work:"work ",
      new:"new ",
      services:"services ",
      careers:"careers ",
      sectors:"sectors ",
      hex_text:"hex text ",
      contact:"contact",
      accept_cookies:"accept cookies",
      menu:"Menu",
    },
    'ar': {
    },
  },
})
export default i18n
